<template>
  <div class="social-block">
    <div class="social-block_inner">
      <div class="social-block_header">
        <img src="/vk_full_logo.svg" alt="">
        <div class="social-block_title-r">
          {{ subtitle }}
        </div>
      </div>
      <!--			<div class="social-block_icon" v-if="icon">
              <img src="/vk_full_logo.svg" alt="">
            </div>
            <div class="social-block_title">
              <p>{{ title }}</p>
              <span>{{ subtitle }}</span>
            </div>-->
      <div class="social-block_images">
        <template v-for="(val, i) in images">
          <img :src="`/socfaces/${ val }`" :key="`socbi-${ i }`" alt="">
        </template>
      </div>
      <div class="social-block_footer">
        <span class="social-bloc_count">Нас более 50 000 человек</span>
        <a
          :href="link"
          target="_blank"
          class="social-block_button"
          rel="nofollow"
        >{{ linkText }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    title: String,
    subtitle: String,
    link: String,
    linkText: String,
    images: {
      type: Array,
      default () {
        return []
      },
    },
  },
}
</script>

<style lang="less">
.social-block {
  width: 100%;
  background-color: white;
  border-radius: 30px;
  box-shadow: 0 20px 41px -17px rgba(0, 0, 0, .16);
  position: relative;
  background-image: url("/vk_logo_bg.svg");
  background-position: bottom right;
  background-repeat: no-repeat;

  &_inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 17px 25px 23px 25px;
    position: relative;
    box-sizing: border-box;
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 10px 40px 10px;

    @media screen and (max-width: 500px) {
      justify-content: center;
      flex-direction: column;
      padding-bottom: 20px;

      img {
        margin-bottom: 10px;
      }
    }

  }

  &_title-r {
    font-size: 13px;
  }

  &_icon {
    font-size: 23px;
    color: #E2E2E2;
    position: absolute;
    top: 20px;
    left: 18px;
  }

  &_title {
    font-family: 'Roboto', Arial, sans-serif;
    text-align: center;
    margin-bottom: 30px;

    p {
      font-size: 19px;
      padding-bottom: 8px;
    }

    span {
      font-size: 13px;
    }
  }

  &_images {
    max-width: 100%;
    /*width: 220px;*/
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;

    @media screen and (max-width: 992px) {
      height: 228px;
      overflow: hidden;
      margin-bottom: 30px;
    }

    img {
      width: 40px;
      height: 40px;
      margin-bottom: 20px;
      margin-left: 10px;
      margin-right: 10px;

      @media screen and (min-width: 1800px) {
        margin-left: 13px;
        margin-right: 13px;
      }

      @media screen and (max-width: 1200px) {
        margin-left: 7px;
        margin-right: 7px;
      }

      @media screen and (max-width: 992px) {
        margin-left: 15px;
        margin-right: 15px;
      }

      @media screen and (max-width: 500px) {
        margin-left: 13px;
        margin-right: 13px;
      }
    }
  }

  &_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;

    span {
      font-size: 13px;

      @media screen and (max-width: 500px) {
        display: none;
      }
    }
  }

  &_count {
    font-size: 13px;
  }

  &_button {
    max-width: 100%;
    width: 228px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E31E24;
    background: linear-gradient(180deg, #fc5964 0%, #e30615 100%);
    font-family: 'Roboto', Arial, sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: white;
    text-decoration: none;
    padding: 10px 15px;
    border: none;
    border-radius: 10px;
    box-sizing: border-box;
    cursor: pointer;

    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
}
</style>
