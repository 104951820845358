var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"quick-selection"},[_c('div',{staticClass:"quick-selection_inner"},[_c('div',{staticClass:"quick-selection_head"},[_c('InnerTitle',{attrs:{"icon":"s-lightning.png"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n                Быстрый подбор\n              ")]},proxy:true}])})],1),_vm._v(" "),_c('div',{staticClass:"quick-selection_tag_group"},_vm._l((_vm.getQuickSortTags),function(val,i){return _c('nuxt-link',{key:`qst-${ i }`,staticClass:"quick-selection_tag",attrs:{"to":{
                name: 'menu-name',
                params: {
                  name: val.slug,
                  defaultCompilation: val.params.defaultCompilation,
                }
              },"no-prefetch":""}},[_vm._v("\n              "+_vm._s(val.tag)+"\n            ")])}),1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }