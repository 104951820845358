<template>
  <div class="news-banner">
    <div v-for="(val, i) in items" :key="`newsbanner-${ i }`" class="news-banner_item">
      <div class="news-banner_photo">
        <nuxt-link :to="`/news/${ val.id }`" class="news-banner_more">
          <img :src="val.image" alt="">
        </nuxt-link>
      </div>
      <div class="news-banner_info">
        <div class="news-banner_info_title">
          <p>{{ val.name }}</p>
        </div>
        <div class="news-banner_info_date">
          <span>{{ val.date }}</span>
        </div>
        <div class="news-banner_info_excerpt">
          <p>{{ val.subName }}</p>
        </div>
        <nuxt-link :to="`/news/${ val.id }`" class="news-banner_more">
          Читать полностью..
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      items: [],
    }
  },
  async fetch () {
    try {
      const res = await this.$axios.get(this.$store.getters['api/getNews'], {
        params: {
          limit: 4,
          offset: 0,
          page: 0,
        },
      })
      this.items = res.data.items
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    getExcerpt (description) {
      return description.substring(123, 0) + (description.length <= 123 ? '' : '...')
    },
  },
}
</script>

<style lang="less">
.news-banner {
	display: flex;
	flex-direction: column;

	&_item {
		display: flex;
		align-items: center;

		@media screen and (max-width: 992px) {
			flex-direction: column;
		}

		&:not(:last-child) {
			margin-bottom: 12px;

			@media screen and (max-width: 992px) { margin-bottom: 30px; }
		}
	}

	&_photo {
		flex-shrink: 0;
		margin-right: 25px;

		@media screen and (max-width: 1200px) { margin-right: 10px; }
		@media screen and (max-width: 992px) {
			margin-right: 0;
			margin-bottom: 21px;
		}

		img {
			width: 55px;
			height: 55px;
			border: 5px solid #FFFFFF;
			border-radius: 50%;
			object-fit: cover;
			object-position: center;

			@media screen and (max-width: 1200px) {
				width: 75px;
				height: 75px;
			}

			@media screen and (max-width: 992px) {
				width: 150px;
				height: 150px;
			}
		}
	}

	&_info {
		display: flex;
		flex-direction: column;

		@media screen and (max-width: 992px) {
			align-self: center;
			text-align: center;
		}

		&_title {
			margin-bottom: 5px;

			p {
				font-family: 'Aqum';
				font-weight: 500;
				font-size: 12px;
			}
		}

		&_date {
			margin-bottom: 4px;

			span {
				font-family: 'Roboto';
				font-size: 14px;
			}
		}

		&_excerpt {
			margin-bottom: 6px;

			p {
				font-family: 'Roboto';
				font-weight: 300;
				font-size: 14px;
				line-height: 18px;
			}
		}
	}

	&_more {
		font-family: 'Roboto';
		font-weight: 500;
		font-size: 14px;
		color: #E31E24;
		text-decoration: none;
	}
}
</style>
