<template>
  <div class="delivery_info">
    <div v-if="text" v-html="text" class="delivery_info_text" />
    <!-- <MicroBanner />-->
  </div>
</template>

<script>
import MicroBanner from '@/components/page/BannerMicro'
import { mapGetters } from 'vuex'

export default {
  components: { MicroBanner },
  computed: {
    ...mapGetters({
      text: 'seo/getText',
    }),
  },
}
</script>

<style lang="less">
.delivery_info {
  @media screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_text {
    margin-bottom: 30px;

    @media screen and (max-width: 992px) {
      margin-bottom: 50px;
    }

    p, ul {
      font-family: 'Roboto', Arial, sans-serif;
      font-weight: 300;
      font-size: 13px;
      color: black;
      line-height: 18px;
      text-align: justify;
      text-indent: 15px;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    ul {
      padding-left: 5px;
      text-indent: 0;
      li {
        padding-left: 10px;
        list-style-type: "-";
        margin-bottom: 5px;
      }
    }

    h2, h3, h4 {
      margin-bottom: 5px;
    }
  }
}
</style>
