<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="masonry">
          <nuxt-link
            v-for="(val, i) in getLinkItems"
            :key="`masonry-${ i }`"
            :to="val.link"
            class="masonry_item"
          >
            <div :style="{ 'background-image': `url('/compilations/${ val.img }')` }" class="masonry_item_inner">
              <div class="masonry_item_text">
                <span v-html="val.name" />
              </div>
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
  },
  data () {
    return {
      menuCompilation: [
        {
          name: 'Запеченные \n роллы',
          img: 'bakedrolls.png',
          link: { name: 'menu-name', params: { name: 'baked-rolls', defaultCompilation: null } },
          class: '',
        },
        {
          name: 'Сеты',
          img: 'sety.png',
          link: { name: 'menu-name', params: { name: 'sets', defaultCompilation: null } },
          class: '',
        },
        {
          name: 'Напитки',
          img: 'napitky.png',
          link: { name: 'menu-name', params: { name: 'drinks', defaultCompilation: null } },
          class: 't-top',
        },
        {
          name: 'Холодные роллы',
          img: 'rolls.png',
          link: { name: 'menu-name', params: { name: 'rolls', defaultCompilation: null /* 'masonrycoldrolls' */ } },
          class: '',
        },
        {
          name: 'Горячее',
          img: 'hot.png',
          link: { name: 'menu-name', params: { name: 'hot', defaultCompilation: null } },
          class: 't-top',
        },
        {
          name: 'Десерты',
          img: 'deserty.png',
          link: { name: 'menu-name', params: { name: 'deserts', defaultCompilation: null } },
          class: 't-top',
        },
        {
          name: 'Суши',
          img: 'sushii-2.png',
          link: { name: 'menu-name', params: { name: 'sushi', defaultCompilation: null } },
          class: 't-top',
        },
        {
          name: 'Салаты <br>и закуски',
          img: 'poke2.png',
          link: { name: 'menu-name', params: { name: 'snacks', defaultCompilation: null } },
          class: '',
        },
      ],
    }
  },
  computed: {
    getLinkItems () {
      return this.items.length ? this.items : this.menuCompilation
    },
  },
}
</script>

<style lang="less">
.masonry {
  --rowHeight: 175px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: var(--rowHeight) var(--rowHeight) var(--rowHeight);
  gap: 18px 23px;
  grid-template-areas:
    "rolls rolls pizza snacks"
    "coldrolls sets pizza soup"
    "coldrolls sushi combo combo";

  @media screen and (max-width: 992px) {
    --rowHeight: 120px;
  }

  @media screen and (max-width: 768px) {
    --rowHeight: 110px;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: var(--rowHeight) var(--rowHeight) var(--rowHeight) var(--rowHeight) var(--rowHeight) var(--rowHeight);
    gap: 10px;
    grid-template-areas:
      "rolls rolls"
      'coldrolls sets'
      "coldrolls sushi"
      "snacks pizza"
      "soup pizza"
      "combo combo";
  }

  &_item {
    display: inline-block;
    background-color: #F1F1F1;
    color: black;
    text-decoration: none;
    border-radius: 15px;
    position: relative;
    transition: all .3s;
    overflow: hidden;
    cursor: pointer;

    &:hover, &:focus {
      background-color: #E31E24;

      span {
        color: white;
      }
    }

    &_inner {
      width: 100%;
      height: 100%;
      display: flex;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right bottom;
      padding: 30px;
      border-radius: 15px;
      box-sizing: border-box;

      @media screen and (max-width: 992px) {
        padding: 25px;
      }
    }

    &:nth-child(8n + 1) {
      grid-area: rolls;

      & > .masonry_item_inner {
        align-items: center;
      }
    }

    &:nth-child(8n + 2) {
      grid-area: pizza;

      & > .masonry_item_inner {
        justify-content: center;
        padding-top: 70px;
        background-size: cover;

        @media screen and (max-width: 992px) {
          padding-top: 25px;
        }
      }

    }

    &:nth-child(8n + 3) {
      grid-area: snacks;

      & > .masonry_item_inner {
        /*align-items: center;*/
      }
    }

    &:nth-child(8n + 4) {
      grid-area: coldrolls;

      & > .masonry_item_inner {
        padding-top: 30px;

        @media screen and (max-width: 992px) {
          padding-top: 32px;
          background-size: 80%;
        }
      }
    }

    &:nth-child(8n + 5) {
      grid-area: sets;

      & > .masonry_item_inner {
        padding-top: 30px;

        @media screen and (max-width: 992px) {
          padding-top: 25px;
          align-items: flex-start;
          background-size: 75%;

        }
      }
    }

    &:nth-child(8n + 6) {
      grid-area: soup;

      & > .masonry_item_inner {
        padding-top: 30px;

        @media screen and (max-width: 992px) {
          padding-top: 30px;
          /* align-items: center; */
          background-size: 70%;
        }
      }
    }

    &:nth-child(8n + 7) {
      grid-area: sushi;

      & > .masonry_item_inner {
        align-items: flex-start;
      }
    }

    &:nth-child(8n + 8) {
      grid-area: combo;

      & > .masonry_item_inner {
        align-items: center;
        padding-left: 60px;

        @media screen and (max-width: 992px) {
          padding-left: 25px;
        }
      }
    }

    &_text {
      span {
        font-family: 'Aqum', Arial, sans-serif;
        font-weight: 500;
        font-size: 19px;
        color: black;
        transition: color .3s;
        white-space: pre-wrap;
        line-height: 1.3;

        @media screen and (max-width: 992px) {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
