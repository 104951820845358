<template>
  <div>
    <template v-if="!withContainer">
      <div class="inner_title_prop">
        <InnerTitle>
          <template v-if="title" v-slot:title>
            {{ title }}
          </template>
          <template v-if="subtitle" v-slot:subtitle>
            {{ subtitle }}
          </template>
        </InnerTitle>
        <div class="inner_title_prop_image">
          <img :src="image" v-if="image" alt="">
          <span v-if="imageProp">{{ imageProp }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="inner_title_prop">
              <InnerTitle>
                <template v-if="title" v-slot:title>
                  {{ title }}
                </template>
                <template v-if="subtitle" v-slot:subtitle>
                  {{ subtitle }}
                </template>
              </InnerTitle>
              <div class="inner_title_prop_image">
                <img :src="image" v-if="image" alt="">
                <span v-if="imageProp">{{ imageProp }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import InnerTitle from './InnerTitle'

export default {
  components: { InnerTitle },
  props: {
    title: String,
    subtitle: String,
    image: String,
    imageProp: String,
    withContainer: {
      type: Boolean,
      default () {
        return false
      },
    },
  },
}
</script>

<style lang="less">
.inner_title_prop {
	width: 100%;
	display: flex;
	justify-content: space-between;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;

		.inner-title {
			position: relative;

			&_text {
				p {
					text-align: center;
					margin-bottom: 66px;
				}

				span { text-align: center; }
			}
		}
	}

	&_image {
		display: flex;
		align-items: center;

		@media screen and (max-width: 768px) {
			position: absolute;
			top: 53%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		img {
			height: 53px;
			width: auto;
			margin-right: 8px;
		}

		span {
			font-family: 'Roboto Slab', Arial, sans-serif;
			font-weight: 500;
			font-size: 40px;
			color: #D9D9D9;
		}
	}
}
</style>
