<template>
  <div class="intouch">
    <div class="container">
      <div :style="{ 'flex-direction': short ? 'row-reverse' : 'row' }" class="row">
        <div class="col-12 col-lg-7">
          <div class="intouch_socials">
            <div class="intouch_socials_item">
              <SocialBlock
                :images="getSocImagesVk"
                :link="$region.getSocialPath('vk')"
                icon="vk"
                title="Группа ВК"
                subtitle="Давайте дружить в Вконтакте!"
                link-text="Вступить"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-5">
          <div :class="{ 'short': short }" class="intouch_title">
            Будем<br>на связи
            <div class="intouch_title_icon">
              <img src="/wbit.svg" alt="">
            </div>
          </div>
          <div class="intouch_info">
            <p>
              Подписывайся к нам, у нас интересно!
            </p>
            <!--            <small>подробности конкурса читайте в наших социальных сетях</small>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocialBlock from './../UI/SocialBlock'

export default {
  components: { SocialBlock },
  props: {
    short: {
      type: Boolean,
      default () {
        return false
      },
    },
  },
  data () {
    return {
      vkImages: [
        '3/01.png', '3/02.png', '3/03.png', '3/04.png', '3/05.png', '3/06.png', '3/07.png', '3/08.png', '3/09.png', '3/10.png', '3/11.png', '3/12.png', '3/13.png', '3/14.png', '3/15.png', '3/16.png', '3/17.png', '3/18.png', '3/19.png', '3/20.png', '3/21.png', '3/22.png', '3/23.png', '3/24.png', '3/25.png', '3/26.png', '3/27.png', '3/28.png', '3/29.png', '3/30.png', '3/31.png', '3/32.png', '3/33.png', '3/34.png', '3/35.png', '3/36.png',
      ],
      instaImages: [ ],
    }
  },
  computed: {
    getSocImagesVk () {
      return this.short ? this.vkImages.slice(0, 18) : this.vkImages
    },
    getSocImagesInsta () {
      return this.short ? this.instaImages.slice(0, 8) : this.instaImages
    },
  },
}
</script>

<style lang="less">
.intouch {
	width: 100%;

	@media screen and (max-width: 992px) {
		& > .container > .row > div[class^="col"] {
			&:last-child {
				order: -1;
			}
		}
	}

	&_socials {
		display: flex;
		justify-content: space-between;

		@media screen and (max-width: 992px) {
			flex-direction: column;
			align-items: center;
			margin-top: 35px;
		}

		&_item {
			flex-grow: 1;

			@media screen and (max-width: 992px) { width: 100%; }

			&:not(:last-child) {
				margin-right: 20px;

				@media screen and (max-width: 992px) {
					margin-right: 0;
					margin-bottom: 17px;
				}
			}
		}
	}

	&_title {
		font-family: 'Roboto Slab', Arial, sans-serif;
		font-weight: 500;
		font-size: 63px;
		color: black;
		margin-top: 50px;
		margin-bottom: 11px;
		position: relative;

		@media screen and (max-width: 1200px) {
			font-size: 55px;
			margin-top: 130px;
		}

		@media screen and (max-width: 992px) {
			display: inline-block;
			margin-top: 0;
		}

		&.short { margin-top: 10px; }

		&_icon {
			position: absolute;
			top: -125px;
			right: -10px;

			@media screen and (max-width: 1200px) { right: -77px; }
			@media screen and (max-width: 992px) { right: -180px; }
		}
	}

	&_info {
		p {
			font-family: 'Roboto', Arial, sans-serif;
			font-weight: 300;
			font-size: 25px;
			color: black;

			@media screen and (max-width: 1200px) {
				font-size: 16px;
				line-height: 19px;
			}
		}

		strong { font-weight: normal; }

		small {
			display: block;
			font-family: 'Roboto', Arial, sans-serif;
			font-weight: 300;
			font-size: 18px;
			color: #B7B7B7;
			margin-top: 17px;

			@media screen and (max-width: 1200px) {
				font-size: 12px;
				margin-top: 15px;
			}
		}
	}
}
</style>
