<template>
  <div class="pizza_slider">
    <div class="container">
      <div class="row">
        <div :class="{'col--flex': !client}" class="col">
          <swiper v-if="client" :options="sliderOptions">
            <!--							<swiper-slide>-->
            <!--								<Card :data="pizzaBuildData" type="pizza" :pizzaBuild="true" :stopModal="true" />-->
            <!--							</swiper-slide>-->
            <swiper-slide v-for="(val, i) in recomendedPizza" :key="`recompizza-${ i }`">
              <Card :data="val" :stopModal="true" type="pizza" />
            </swiper-slide>
            <div id="pizzaslider" slot="pagination" class="swiper-pagination" />
          </swiper>
          <template v-else>
            <div v-for="(val, i) in recomendedPizza" :key="`recompizza-${ i }`" class="col__cell">
              <Card :data="val" :stopModal="true" type="pizza" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from './Card'

export default {
  components: { Card },
  props: {
    sliderOptions: {
      type: Object,
      default () {
        return {
          slidesPerView: 3,
          spaceBetween: 27,
          autoplay: true,
          pagination: {
            el: '#pizzaslider',
            type: 'bullets',
          },
          breakpoints: {
            992: { slidesPerView: 3 },
            768: { slidesPerView: 2.2 },
            0: { slidesPerView: 1.2 },
          },
        }
      },
    },
  },
  data () {
    return {
      recomendedPizza: [],
      pizzaBuildData: {
        bonusAdd: 0,
        bundleItems: 0,
        composition: '',
        description: 'Собери свою пиццу 30 см с двумя разными вкусами',
        h1: 'h1',
        hasStop: false,
        id: 99999,
        image: 'goods/pizzacombo.png',
        modifications: [],
        name: 'Пицца из половинок',
        needPersonCount: false,
        oldPrice: null,
        price: 520,
        slug: 'pizza-build',
        type: 'bundle',
      },
      client: false,
    }
  },
  async fetch () {
    try {
      const res = await this.$store.dispatch('menu/fetchCollections', { target: 'home_tasty_pizza', type: 'desktop' })
      this.recomendedPizza = res.items[0].menuItems
    } catch (e) {
      console.log(e)
    }
  },
  mounted () {
    this.client = process.client
  },
}
</script>

<style lang="less">
.pizza_slider {
  .swiper {
    &-container {
      padding-bottom: 40px;
      margin-bottom: -40px;
    }

    &-slide {
      height: auto;

      .card {
        height: 100%;
      }
    }

    &-pagination {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      &-bullet {
        width: 13px;
        height: 7px;
        background-color: @swiper--pagination-bullet;
        border-radius: 20px;

        &:not(:last-child) {
          margin-right: 7px;
        }

        &-active {
          width: 28px;
          background-color: @swiper--pagination-bullet-active;
        }
      }
    }
  }

  .col--flex {
    display: flex;
    flex-wrap: wrap;
    margin-left: -9px;
    margin-right: -9px;
  }

  .col__cell {
    margin: 9px;
    width: 334px;
    max-width: 100%;
  }
}
</style>
