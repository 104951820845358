<template>
  <div class="content">
    <div class="content_slider">
      <MainSlider />
    </div>
    <div class="content_grid">
      <Masonry />
    </div>
    <div class="content_quick-selection">
      <QuickSelection :rollItems="rollQuickItems" :pizzaItems="pizzaQuickItems" />
    </div>
    <div class="content_recomended">
      <Recomended />
    </div>
    <div class="content_bottom">
      <div class="content_pizza">
        <div class="content_pizza_title">
          <InnerTitleProp
            :withContainer="true"
            title="Лучшие вкусы под твое настроение"
            subtitle="Выбирай и наслаждайся"
          />
        </div>
        <PizzaSlider />
      </div>
      <!--      <div class="content_download">
        <DownloadJumbo />
      </div>-->
      <div class="content_other">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="content_other_title">
                <h1>{{ pageTitle }}</h1>
              </div>
              <DeliveryInfo />
            </div>
            <div class="col-12 col-lg-6">
              <div class="content_other_title">
                <p>Последние события</p>
              </div>
              <NewsBanner />
            </div>
          </div>
        </div>
      </div>
      <div class="content_willbeintouch">
        <WillBeInTouchHome />
      </div>
    </div>
  </div>
</template>

<script>
import MainSlider from '@/components/page/MainSlider'
import Masonry from '@/components/page/MasonryGrid'
import QuickSelection from '@/components/page/QuickSelection'
import Recomended from '@/components/page/Recomended'
import PizzaSlider from '@/components/page/PizzaSlider'
import DownloadJumbo from '@/components/page/DownloadJumbo'
import DeliveryInfo from '@/components/page/DeliveryInfo'
import NewsBanner from '@/components/page/NewsBanner'
import WillBeInTouch from '@/components/page/WillBeInTouch'
import InnerTitleProp from '@/components/UI/InnerTitleProp'
import { mapGetters } from 'vuex'
import WillBeInTouchHome from '~/components/page/WillBeInTouchHome'

export default {
  components: {
    WillBeInTouchHome,
    MainSlider,
    Masonry,
    QuickSelection,
    Recomended,
    DownloadJumbo,
    DeliveryInfo,
    NewsBanner,
    WillBeInTouch,
    PizzaSlider,
    InnerTitleProp,
  },
  data () {
    return {
      rollQuickItems: [],
      pizzaQuickItems: [],
    }
  },
  computed: {
    ...mapGetters({
      pageTitle: 'seo/getPageTitle',
    }),
  },
  async asyncData ({ store }) {
    /** Set default meta tags */
    store.commit('seo/setMetaFieldsDefaults', {
      metaTitle: `Доставка суши ${store.getters['region/getCurRegionNamePadeg']} — заказать суши на дом и в офис | СУШИСЕТ`,
      metaDescription: `Заказывайте быструю доставку вкусных суши из свежайших продуктов домой или в ' +
        'офис ${store.getters['region/getCurRegionNamePadeg']}.`,
      pageTitle: 'Быстрая доставка суши на дом и в офис от СУШИСЕТ!',
    })

    let rollQuickItems = []
    let pizzaQuickItems = []

    const rollQuickItemsPromise = store.dispatch('menu/fetchCollections', {
      target: 'default_rolls',
      type: 'desktop',
      onlyList: true,
    })
      .then((quickRolls) => {
        if (quickRolls) {
          return quickRolls.items.map((v) => {
            return {
              tag: v.name.toLowerCase(),
              slug: v.category ? v.category.slug : '',
              params: {
                defaultCompilation: v.slug,
              },
            }
          })
        }
        return []
      })

    const pizzaQuickItemsPromise = store.dispatch('menu/fetchCollections', {
      target: 'default_pizza',
      type: 'desktop',
      onlyList: true,
    })
      .then((quickPizza) => {
        if (quickPizza) {
          return quickPizza.items.map((v) => {
            return {
              tag: v.name.toLowerCase(),
              slug: v.category ? v.category.slug : '',
              params: {
                defaultCompilation: v.slug,
              },
            }
          })
        }
        return []
      })

    const data = await Promise.all([
      rollQuickItemsPromise,
      pizzaQuickItemsPromise,
    ])

    rollQuickItems = data[0]
    pizzaQuickItems = data[1]

    return {
      rollQuickItems,
      pizzaQuickItems,
    }
  },
}
</script>

<style lang="less">
.content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;

  &_slider {
    display: flex;
    //padding-top: 60px;
    margin-bottom: 22px;

    @media screen and (max-width: 992px) {
      padding-top: 20px;
      margin-bottom: 19px;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 16px;
    }
  }

  &_grid {
    display: flex;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      margin-bottom: 11px;
    }
  }

  &_quick-selection {
    margin-bottom: 40px;

    @media screen and (max-width: 992px) {
      margin-bottom: 13px;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 25px;
    }
  }

  &_other {
    margin-bottom: 54px;

    @media screen and (max-width: 1200px) {
      margin-bottom: 49px;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 29px;
    }

    &_title {
      margin-bottom: 30px;

      @media screen and (max-width: 1200px) {
        min-height: 86px;
      }
      @media screen and (max-width: 992px) {
        min-height: unset;
        margin-bottom: 12px;
      }

      p, h1 {
        font-family: 'Aqum', Arial, sans-serif;
        font-weight: 500;
        font-size: 30px;

        @media screen and (max-width: 1200px) {
          font-size: 35px;
        }
        @media screen and (max-width: 992px) {
          font-size: 25px;
          text-align: center;
        }
      }
    }

    & > .container > .row > div[class^="col"] {
      &:not(:last-child) {
        position: relative;

        @media screen and (max-width: 992px) {
          position: static;
          margin-bottom: 30px;
        }

        &:after {
          content: '';
          max-height: 100%;
          width: 1px;
          height: 365px;
          background-color: #D8D8D8;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);

          @media screen and (max-width: 992px) {
            content: unset;
          }
        }
      }
    }
  }

  &_willbeintouch {
    padding-bottom: 53px;
  }

  &_bottom {
    background: url('/triangle.svg'),
      /*url('/leaf.png'),*/
    /*url('/leaf2.png'),*/
    /*url('/leaf3.png'),*/
    /*url('/buble.png'),*/
    /*url('/buble2.png'),*/
    #F5F5F5;
    background-size: auto, auto, auto, auto, auto, contain;
    background-position: top left;
      /*top 485px left 17px,
    top 1010px right 140px,
    top 1400px left 180px,
    top 800px right 240px,
    top 1740px left 270px,*/

    background-repeat: no-repeat;

    @media screen and (max-width: 1200px) {
      background: url('/triangle.svg'), #F5F5F5;
      background-size: contain;
      background-position: top left;
      background-repeat: no-repeat;
    }
  }

  &_pizza {
    margin-bottom: 58px;

    @media screen and (max-width: 768px) {
      margin-bottom: 69px;
    }

    &_title {
      margin-bottom: 47px;

      @media screen and (max-width: 1200px) {
        margin-bottom: 43px;
      }
      @media screen and (max-width: 768px) {
        margin-bottom: 35px;
        position: relative;
      }
    }
  }
}
</style>
