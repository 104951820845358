<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="recomended">
          <div class="recomended_title">
            <InnerTitle icon="nory-recomend-100x100.png">
              <template v-slot:title>
                Нори рекомендует
              </template>
              <template v-slot:subtitle>
                Cамое вкусное на главном экране
              </template>
            </InnerTitle>
          </div>
          <CardGroup :items="recomended" class="recomended_cardlist" />
          <!-- <CardRecomendedSlider :items="recomended" class="recomended_slider"/>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InnerTitle from './../UI/InnerTitle'
import CardGroup from './CardGroup'
import CardRecomendedSlider from './CardRecomendedSlider'

export default {
  components: { InnerTitle, CardGroup, CardRecomendedSlider },
  data () {
    return {
      recomended: [],
    }
  },
  async fetch () {
    try {
      const res = await this.$store.dispatch('menu/fetchCollections', { target: 'recomended', type: 'desktop' })
      this.recomended = res.items[0].menuItems
    } catch (e) {
      console.log(e)
    }
  },
}
</script>

<style lang="less">
.recomended {
	margin-bottom: 60px;

	&_title {
		margin-bottom: 48px;
	}
}
</style>
