<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="quick-selection">
          <div class="quick-selection_inner">
            <div class="quick-selection_head">
              <InnerTitle icon="s-lightning.png">
                <template v-slot:title>
                  Быстрый подбор
                </template>
              </InnerTitle>
              <!--              <div class="quick-selection_head_tabs">
                <ButtonTumbler
                  v-model="quickSortItems"
                  :active="quickSortItems"
                  name-on="Пицца"
                  name-off="Роллы"
                />
              </div>-->
            </div>
            <div class="quick-selection_tag_group">
              <nuxt-link
                v-for="(val, i) in getQuickSortTags"
                :key="`qst-${ i }`"
                :to="{
                  name: 'menu-name',
                  params: {
                    name: val.slug,
                    defaultCompilation: val.params.defaultCompilation,
                  }
                }"
                class="quick-selection_tag"
                no-prefetch
              >
                {{ val.tag }}
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InnerTitle from './../UI/InnerTitle'
import ButtonTumbler from './../UI/ButtonTumbler'

export default {
  components: { InnerTitle, ButtonTumbler },
  props: {
    rollItems: {
      type: Array,
      default: () => ([]),
    },
    pizzaItems: {
      type: Array,
      default: () => ([]),
    },
  },
  data () {
    return {
      quickSortItems: false,
    }
  },
  computed: {
    getActiveQuickSortName () {
      return this.quickSortItems.find(val => val.active).id
    },
    getQuickSortTags () {
      return this.quickSortItems ? this.pizzaItems : this.rollItems
    },
  },
}
</script>

<style lang="less">
.quick-selection {
  width: 100%;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 3px 36px rgba(122, 122, 122, .16);

  &_inner {
    width: 100%;
    padding: 30px 30px 15px 30px;
    padding-bottom: 22px;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
      padding: 15px;
    }
  }

  &_head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    .inner-title_icon img {
      max-height: 43px;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 30px;

      &_tabs {
        margin-top: 15px;
      }
    }
  }

  &_tag {
    height: 36px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    background-color: #F5F5F5;
    border-radius: 10px;
    padding: 0 30px;
    margin-bottom: 18px;
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 14px;
    color: black;
    text-decoration: none;
    transition: all .2s;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      flex-grow: 1;
      margin-bottom: 23px;
    }

    &:not(:last-child) {
      margin-right: 30px;

      @media screen and (max-width: 768px) {
        margin-right: 10px;
      }
    }

    &:hover, &:focus {
      color: white;
      background-color: #E31E24;

      /*&:nth-child(4n + 1) {
        background-color: #3600FF;
      }

      &:nth-child(4n + 2) {
        background-color: #FF9800;
      }

      &:nth-child(4n + 3) {
        background-color: #8800FF;
      }

      &:nth-child(4n + 4) {
        background-color: #FF005F;
      }*/
    }

    &_group {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>
